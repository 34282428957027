<template>
  <el-dialog v-model="dialogUnlockVisible$" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false" :title="`${dialogPayTitle$.text}`" :width="`${w}`"
    custom-class="subscription-dialog">
    
    <template #header>
      <div class="b-users__item__cover__wrapper">
        <!-- {{ dialogCurrentUser$?.profile_banner  }} -->
        <!-- <img src="https://public.onlyfans.com/files/thumbs/w480/s/s3/s3r/s3rmdoljmu4mzia35gj3jg5gzggmhyup1653830347/237262913/header.jpg" alt="2德文" class="b-users__item__cover"> -->
        <div class="rounded img-fluid" :style="`height: 200px;background-size: cover;background-position: center center;background-image: url(${dialogCurrentUser$?.profile_banner});`">
        </div>
      </div>
      <div class="b-profile__user">
        <div class="g-avatar">
          <el-avatar :size="100">
            <img :src="dialogCurrentUser$?.avatar" />
          </el-avatar>
        </div>
        <div class="b-profile__user__info m-with-status">
          <div class="b-profile__names">
            <div class="b-username-row">
              <router-link :to="`/${dialogCurrentUser$?.user_account}`" class="b-username">
                <div class="g-user-name m-verified m-lg-size"> {{ dialogCurrentUser$?.user_name }} </div>
              </router-link>
            </div>
            <div class="b-username-row m-width-limit">
              <router-link :to="`/${dialogCurrentUser$?.user_account}`">
                <div class="g-user-username">{{ dialogCurrentUser$?.user_account }} </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div id="isCard">
      <h3>{{ $t('解鎖觀看內容') }}</h3>
      <div class="d-flex mb-0">
        <div class="d-flex align-items-center">
          <el-icon :size="iconSize" color="#090">
            <Check />
          </el-icon>
        </div>
        <div class="ps-1">{{$t('label.single_unlock_tip1')}}</div>
      </div>
      <div v-if="dialogPayTitle$.item.post_underage === 0" class="d-flex mb-0">
        <div class="d-flex align-items-center">
          <el-icon :size="iconSize" color="#e44">
            <Close />
          </el-icon>
        </div>
        <div class="ps-1">{{$t('label.single_unlock_tip2')}}</div>
      </div>

      <div>
        <div id="supscriptionPayMethod">
          <h5 class="pt-3">{{ $t('label.chooseThePaymentMethod') }}</h5>
          <el-select v-if="isCard$" class="w-100" :placeholder="$t('label.pleaseChooseAPaymentMethod')" v-model=" selectedPayMethod">
            <el-option v-for="item in PayOptions" :key="item.value" :value-key="value" :label="item.label" :value="item.value" />
          </el-select>
          <el-select v-else class="w-100" :placeholder="$t('label.pleaseChooseAPaymentMethod')" v-model=" selectedPayMethodWithoutEwallet">
            <el-option v-for="item in PayOptionsWithoutEwallet" :key="item.value" :value-key="value" :label="item.label" :value="item.value" />
          </el-select>
        </div>

        <div id="payDesc">
          <div class="pay-desc d-flex justify-content-between mt-2">
            <h5>將被收取TWD{{ dialogPayTitle$.price }}</h5>
          </div>
          <!-- <div class="pay-desc d-flex justify-content-between">
            <span class="pt-1">{{ $t('label.subscribeToWatchInstructions') }}</span>
          </div>
          <div class="d-flex justify-content-between">
            <h5 class="pt-1">{{ $t('label.planSelection') }}</h5>
            <el-button size="small" type="primary" @click="selectedOption(1, '', '','','')">
              {{ $t('label.reselect') }}</el-button>
          </div>

          <div class="desc-list">
            <div class="d-flex justify-content-start">
              <span>{{ selectedPayOptionText }}</span>
            </div>
            <div class="d-flex justify-content-between">
              <div>
                <span>
                  {{ $t('label.theAmount') }}：{{toCurrency(selectedPayPrice)}}</span>
              </div>
              <div>
                <span>{{ $t('label.tax') }}：{{ toCurrency(taxPrice) }}</span>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <span>{{ $t('label.paidInTotal') }}：{{ toCurrency(total) }}</span>
            </div>
          </div> -->
          <div v-if="!isCard$" id="isNotCard">
            <div>
              <div class="content-btn d-grid p-2">
                <button type="submit" class="btn btn-primary rounded-pill" @click="showCardForm = !showCardForm">
                  添加信用卡資訊
                </button>
              </div>
            </div>
            <iq-card v-if="showCardForm">
              <template v-slot:body>
                <form>
                  <div class="row align-items-center">
                    <div class="form-group col-sm-6">
                      <label for="fname" class="form-label">{{$t('label.iDNumber')}}</label>
                      <input type="text" v-model="form.user_sn" class="form-control" id="user_sn" name="user_sn" :placeholder="$t('label.pleaseEnterYourIDNumber')"
                        :class="{ 'is-invalid':  v$.form.user_sn.$dirty && !v$.form.user_sn.$pending && v$.form.user_sn.$invalid }" />
                      <span v-if=" v$.form.user_sn.$dirty && !v$.form.user_sn.$pending && v$.form.user_sn.$invalid" class="invalid-feedback">{{ $t('messages.required') }}</span>
                    </div>
                    <div class="form-group col-sm-6">
                      <label for="fname" class="form-label">{{$t('label.email')}}</label>
                      <input type="text" v-model="form.user_email" class="form-control" id="email" name="email" :placeholder="$t('label.emailPlaceholder')"
                        :class="{ 'is-invalid':  v$.form.user_email.$dirty && !v$.form.user_email.$pending && v$.form.user_email.$invalid }" />
                      <span v-if=" v$.form.user_email.$dirty && !v$.form.user_email.$pending && v$.form.user_email.$invalid" class="invalid-feedback">{{ $t('messages.required') }}</span>
                    </div>

                    <div class="form-group col-sm-6">
                      <label for="uname" class="form-label">{{$t('label.cardName')}}</label>
                      <!-- <div class="fab fa-cc-visa ps-2"></div> -->
                      <input type="text" v-model="form.user_real_name" class="form-control" id="card_number" name="card_number" :placeholder="$t('label.cardNamePlaceholder')"
                        :class="{ 'is-invalid':  v$.form.user_real_name.$dirty && !v$.form.user_real_name.$pending && v$.form.user_real_name.$invalid }" maxlength="19" />
                      <span v-if=" v$.form.user_real_name.$dirty && !v$.form.user_real_name.$pending && v$.form.user_real_name.$invalid" class="invalid-feedback">{{ $t('messages.required') }}</span>
                    </div>

                    <div class="form-group col-sm-6">
                      <label for="uname" class="form-label">{{$t('label.cardholderEnglishName')}}</label>
                      <!-- <div class="fab fa-cc-visa ps-2"></div> -->
                      <input type="text" v-model="form.user_english_name" class="form-control" id="user_english_name" name="user_english_name" :placeholder="$t('label.pleaseEnterTheEnglishNameOfTheCardholder')"
                        :class="{ 'is-invalid':  v$.form.user_english_name.$dirty && !v$.form.user_english_name.$pending && v$.form.user_english_name.$invalid }" maxlength="19" />
                      <span v-if=" v$.form.user_english_name.$dirty && !v$.form.user_english_name.$pending && v$.form.user_english_name.$invalid" class="invalid-feedback">{{ $t('messages.required') }}</span>
                    </div>
                    <div class="form-group col-sm-6">
                      <label for="uname" class="form-label">{{$t('label.telephone')}}</label>
                      <input type="text" v-model="form.user_cellphone" class="form-control" id="user_cellphone" name="user_cellphone" :placeholder="$t('label.pleaseEnterPhoneNumber')"
                        :class="{ 'is-invalid':  v$.form.user_cellphone.$dirty && !v$.form.user_cellphone.$pending && v$.form.user_cellphone.$invalid }" maxlength="19" pattern="[0-9]*" />
                      <span v-if=" v$.form.user_cellphone.$dirty && !v$.form.user_cellphone.$pending && v$.form.user_cellphone.$invalid" class="invalid-feedback">{{ $t('messages.required') }}</span>
                    </div>
                    <div class="form-group col-sm-6">
                      <label for="uname" class="form-label">{{$t('label.address')}}</label>
                      <input type="text" v-model="form.user_address" class="form-control" id="user_address" name="user_address" :placeholder="$t('label.pleaseEnterAddress')"
                        :class="{ 'is-invalid':  v$.form.user_address.$dirty && !v$.form.user_address.$pending && v$.form.user_address.$invalid }" maxlength="19" />
                      <span v-if=" v$.form.user_address.$dirty && !v$.form.user_address.$pending && v$.form.user_address.$invalid" class="invalid-feedback">{{ $t('messages.required') }}</span>
                    </div>
                  </div>
                  <div class="d-flex justify-content-end">
                    <button @click="onSubmit()" type="button" class="btn btn-primary me-2">
                      {{$t('button.submit')}}
                    </button>
                  </div>
                </form>
              </template>
            </iq-card>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <div v-if="isCard$" id="isCardFooter" class="dialog-footer">
        <el-button text @click="close">{{ $t('label.cancel') }}</el-button>
        <el-button :loading="onLoading" type="primary" @click="orderCheckout(
          selectedPayMethod, dialogPayTitle$.item.post_id, 'post_untie', 1
        )">{{ $t('label.payment') }}</el-button>
      </div>
      <div v-if="!isCard$" id="isNotCardFooter" class="dialog-footer">
        <el-button text type="primary" @click="store.dispatch('User/dialogPayUnlockAction', false)">關閉</el-button>
        <el-button :loading="onLoading" type="primary" @click="orderCheckout(
          selectedPayMethodWithoutEwallet, dialogPayTitle$.item.post_id, 'post_untie', 1
        )">{{ $t('label.payment') }}</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { Check, Close } from '@element-plus/icons-vue'
import { useConvert } from '@/utils/useConvert'
import { useCheckout } from '@/utils/useCheckout'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { commomData } from '@/utils/commonVmodel'
import { addCreditCard } from '@/api/creditCard'

export default {
  name: 'SubscriptionPay',
  components: {
    Check,
    Close
  },
  props: {
    items: { type: Array, default: () => [] }
  },
  validations() {
    return {
      form: {
        user_email: { required, email },
        user_sn: { required },
        user_cellphone: { required },
        user_real_name: { required },
        user_english_name: { required },
        user_address: { required }
      }
    }
  },
  setup() {
    const v$ = useVuelidate()
    const store = useStore()
    const dialogUnlockVisible$ = computed(() => store.state.User.dialogUnlockVisible)
    const dialogPayTitle$ = computed(() => store.state.User.dialogPayTitle)
    const dialogCurrentUser$ = computed(() => store.state.User.dialogItem)
    const isCard$ = computed(() => store.state.User.user.is_card)
    const w = computed(() => {
      if (window.innerWidth > 1000 && window.innerWidth < 1200) {
        return '50%'
      } else if (window.innerWidth > 1200 && window.innerWidth < 2000) {
        return '40%'
      } else {
        return window.innerWidth - window.innerWidth / 10 + 'px'
      }
    })

    const { orderCheckout, onLoading, PayOptions, PayOptionsWithoutEwallet } = useCheckout()
    const { toCurrency, payIdToString } = useConvert()
    const selectedPayMethod = ref('ewallet') // 付款方式
    const selectedPayMethodWithoutEwallet = ref('e_collection') // 付款方式
    const selectedPayType = ref(0) // 訂單類型
    const selectedPayPriceFormat = ref(0) // 金額格式化
    const selectedPayPrice = ref(0) // 訂單金額
    const taxPercentage = ref(0.05) // 稅金％
    const taxPrice = ref(0) // 稅金
    const total = ref(0) // 總金額

    const close = () => {
      setDefaultOpt()
      store.dispatch('User/dialogPayUnlockAction', false)
    }

    const setDefaultOpt = () => {
      selectedPayType.value = 0
      selectedPayPriceFormat.value = 0
      selectedPayPrice.value = 0
      taxPrice.value = 0 // 稅金
      total.value = 0 // 總金額
    }

    return {
      store,
      dialogUnlockVisible$,
      dialogPayTitle$,
      dialogCurrentUser$,
      isCard$,
      w,
      selectedPayMethod,
      selectedPayMethodWithoutEwallet,
      selectedPayType,
      selectedPayPrice,
      selectedPayPriceFormat,
      taxPrice,
      total,
      taxPercentage,
      toCurrency,
      payIdToString,
      PayOptions,
      PayOptionsWithoutEwallet,
      orderCheckout,
      onLoading,
      close,
      v$
    }
  },
  data() {
    return {
      commomData: commomData(),
      form: {
        user_sn: '',
        user_cellphone: '',
        user_real_name: '',
        user_email: '',
        user_english_name: '',
        user_address: ''
      },
      showCardForm: false
    }
  },
  watch: {
    'form.card_number'() {
      // Card number without dash (-)
      let realNumber = this.form.card_number.replace(/-/gi, '')

      // Generate dashed number
      let dashedNumber = realNumber.match(/.{1,4}/g)

      // Replace the dashed number with the real one
      this.form.card_number = dashedNumber.join('-')
    }
  },
  methods: {
    async onSubmit() {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }
      this.commomData.btnLoader = true
      addCreditCard(this.form)
        .then(res => {
          console.log(res)
          this.showCardForm = false
          this.$store.dispatch('User/fetchUserAction')
          this.commomData.btnLoader = false
        })
        .catch(err => {
          this.commomData.btnLoader = false
          console.log(err)
          this.$swal.mixin().fire({
            icon: 'error',
            text: err.response.data.message
          })
        })
    }
  }
}

</script>

<style scoped>
.pay-desc {
  font-size: 10px;
}
</style>
