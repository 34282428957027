export function useBlob () {
  const toBlob = mediaUrl => {
    // try {
    //   const response = await fetch(mediaUrl, {
    //     method: 'POST'
    //     // headers: { 'Content-Type': 'application/json' }
    //   }).then(r => r.blob())
    //   console.log(response)
    //   const blob = new Blob([buffer], { type: 'video/mp4' })
    //   // 现在你可以对生成的 Blob 对象进行进一步操作，比如上传到服务器或者在客户端进行处理
    //   console.log(blob)
    //   return blob
    // } catch (error) {
    //   console.error(error)
    // }
    return mediaUrl
  }

  return {
    toBlob
  }
}
